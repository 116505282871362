<template>
  <div class="vpn">
    <download v-if="currentRoutePath == '/vpn'"></download>
    <router-view v-else></router-view>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { computed } from "vue";
import download from '@/pages/vpn/download'
console.log('--lb isInVPN')
localStorage.removeItem('block-war-loginRes');
let router = useRouter();
let currentRoutePath = computed(() => {
  return router.currentRoute.value.path;
});
console.log(router.currentRoute.value)
</script>

<style lang='scss'>
@font-face {
  font-family: "Nexa";
  src: url("../../assets/font/NexaBold.otf");
}

html,
body {
  background: url("https://cdn.blockwar.io/web_assets/img/vpn/bg_page.png") repeat center center;
  background-size: 1.98rem auto;
}

#app,#app .vue_app {
  padding-top: 0;
  color: #fff;

  .head {
    display: none !important;
  }

  .vpn {
    font-family: "Segoe UI", Arial, "Helvetica Neue", Helvetica, sans-serif;

    .btn {
      height: 0.42rem;
      font-size: 0.16rem;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      cursor: pointer;

      &.btn_border {
        border: 1px solid #ef4f55;
        color: #ef4f55;

        &:hover {
          color: #fff;
          background: #ef4f55;
          border-color: #ef4f55;
        }
      }

      &.btn_red {
        color: #fff;
        background: linear-gradient(198.17deg, #ff8688 9.86%, #e9373e 87.58%);

        &:hover {
          background: linear-gradient(0deg,
              rgba(255, 255, 255, 0.1),
              rgba(255, 255, 255, 0.1)),
            linear-gradient(198.17deg, #ff8688 9.86%, #e9373e 87.58%);
        }
      }

      &.dis {
        background: #3c3f52 !important;
        color: #fff !important;
        cursor: default !important;
      }
    }

    .form {
      margin-bottom: 0.5rem;

      .label {
        font-size: 0.18rem;
      }

      input {
        display: block;
        font-size: 0.14rem;
        color: #2f3367;
        margin: 0.1rem 0 0.3rem 0;
        width: 4.5rem;
        line-height: 0.48rem;
        box-sizing: border-box;
        border: 2px solid rgba(27, 32, 63, 0.15);
        border-radius: 0.06rem;
        outline: none;
        padding: 0 0.2rem;
        font-family: "Segoe UI", Arial, "Helvetica Neue", Helvetica, sans-serif;

        &:focus {
          border-color: #1b203f;
        }
      }
    }

    .layout.signup,
    .layout.login {
      width: 8rem;
      margin: 0 auto;

      .title {
        margin-top: 1.2rem;
        margin-bottom: 0.9rem;
        text-align: center;

        svg {
          width: 1.67rem;
          height: auto;
        }
      }

      .box {
        padding: 0.3rem 1.75rem;
        width: 8rem;
        box-sizing: border-box;
        min-height: 6rem;
        background-color: #fff;
        border: 1px solid rgba(27, 32, 63, 0.1);
        border-radius: 0.1rem;
        font-family: "Nexa";
        color: #1b203f;

        h1 {
          margin: 0 0 0.3rem 0;
          font-size: 0.35rem;
          line-height: 0.53rem;
          text-align: center;
        }

        .btnbox {
          display: flex;
          justify-content: space-between;

          .btn_border {
            width: 1.24rem;
          }

          .btn_red {
            width: 3.1rem;
          }
        }
      }
    }
  }
}
</style>